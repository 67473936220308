<template>
    <main class="order order-flight">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="navsteps navstepts-flight">
                        <div class="navsteps__item">
                            <div class="number">1</div>
                            <div class="descr">Разрахунок</div>
                        </div>
                        <div class="navsteps__item">
                            <div class="number">2</div>
                            <div class="descr">Вибір СК</div>
                        </div>
                        <div class="navsteps__item active">
                            <div class="number">3</div>
                            <div class="descr">Оформлення</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="orders osago">
            <div class="container">
                <div class="row row-reverce">
                    <div class="col-lg-12">
                        <!--                        <router-link :to="{ path: $routerHistory.previous().path }" class="go-back">-->
                        <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
                            Повернутися до оформлення
                        </a>
                        <!--                        </router-link>-->

                        <div class="params-search">
                            <a class="params-search__toggler collapsed" data-toggle="collapse" data-target="#searchcollapse" aria-expanded="false" aria-controls="#searchcollapse">
                                <span>Параметри полісу</span>
                                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2795 2.75059e-08L11 0.69496L5.5 6L-2.31891e-07 0.694961L0.7205 4.45343e-07L5.50275 4.61273L10.2795 2.75059e-08Z" fill="black"/>
                                </svg>
                            </a>
                            <div class="collapse" id="searchcollapse">
                                <div class="params-search__content">
                                    <div class="info">
                                        <b>Страхування квитка</b>
                                        <div class="logo">
                                            <img v-if="order.picture" :src="order.picture" alt="logo">
                                            <img v-else src="images/aboutUs-insurance_icon.svg" alt="logo">
                                        </div>
                                    </div>
                                    <div class="params">
                                        <div class="params-item">
                                            <p>Франшиза:</p>
                                            <span>0 грн</span>
                                        </div>
                                        <div class="params-item">
                                            <p>100% вартості квитка:</p>
                                            <span>{{order.ticketPrice}} грн</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Вартість:</p>
                                            <span v-if="order.fullPrice">{{order.fullPrice}} грн</span>
                                            <span v-else>{{order.price}} грн</span>
                                        </div>
                                    </div>
                                    <div class="params-price">
                                        <p>Загальна вартість:</p>
                                        <b v-if="order.fullPrice">{{order.fullPrice}} <span>грн</span></b>
                                        <b v-else>{{order.price}} <span>грн</span></b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row flex-lg-row-reverse" style="position: relative;">
                            <div class="finnaly-column col-lg-3">
                                <div class="sticky-wrapp">
                                    <AutocivilPromo></AutocivilPromo>
                                </div>
                            </div>

                            <form class="finnaly-form col-lg-9" @submit.prevent="submitHandler">
                            <div class="row">
                                <div class="col-12">
                                    <h2>Оформления страхування</h2>
                                </div>
                                <div class="col-12">
                                    <h3>
                                        Перевірте дані та перейдіть до оплати:
                                    </h3>
                                </div>
                                <div ref="ticketsection" :class="['row col-12 finnaly-container', {'disabled-container' : !createTicketInfo}]">
                                    <button class="edit-btn" type="button" @click="createTicketInfo = !createTicketInfo">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#007BBB"/>
                                        </svg>
                                    </button>
                                    <button class="save-btn" type="button" @click="saveChange()">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#fff"/>
                                        </svg>
                                        <span>Зберегти зміни</span>
                                    </button>
                                    <div class="col-12">
                                        <div class="info-container_heading">Дані квитка</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Звідки</div>
                                        <div class="form-group">
                                            <citySearch
                                                :citySelect='citySelect'
                                                v-bind:cityStore="cityStoreFrom"
                                            />
                                            <small class="error" v-if="!$v.order.from.required && submitCheck">Вкажіть звідки</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Куди</div>
                                        <div class="form-group">
                                            <citySearch
                                                :citySelect='citySelectTo'
                                                v-bind:cityStore="cityStoreTo"
                                            />
                                            <small class="error" v-if="!$v.order.to.required && submitCheck">Вкажіть куди</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Дата та час від’їзду</div>
                                        <div class="form-group">
                                            <input v-model="order.startDate" v-mask="'##.##.#### ##:##'" type="text" placeholder="Дати від’їзду" :class="['form-control', 'date-input', {invalid: (!$v.order.startDate.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.startDate.required && submitCheck">Вкажіть дати від’їзду</small>
                                            <small class="error" v-else-if="!$v.order.startDate.minLength">Введіть дату в форматі 12.12.2022 12:12</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Дата та час прибуття</div>
                                        <div class="form-group">
                                            <input v-model="order.endDate" v-mask="'##.##.#### ##:##'" type="text" placeholder="Дати прибуття" :class="['form-control', 'date-input', {invalid: (!$v.order.endDate.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.endDate.required && submitCheck">Вкажіть дати прибуття</small>
                                            <small class="error" v-else-if="!$v.order.endDate.minLength">Введіть дату в форматі 12.12.2022 12:12</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Номер квитка</div>
                                        <div class="form-group">
                                            <input v-model="order.ticketNumber" type="text" placeholder="Номер квитка" :class="['form-control', {invalid: (!$v.order.ticketNumber.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.ticketNumber.required && submitCheck">Вкажіть номер квитка</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Вартість квитка</div>
                                        <div class="form-group">
                                            <div class="text-value">{{order.ticketPrice}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="info-delimetr"></div>
                                </div>
                                <div :class="['row col-12 finnaly-container', {'disabled-container' : !createUserInfo}]">
                                    <button class="edit-btn" type="button" @click="createUserInfo = !createUserInfo">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#007BBB"/>
                                        </svg>
                                    </button>
                                    <button class="save-btn" type="button" @click="saveChange()">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#fff"/>
                                        </svg>
                                        <span>Зберегти зміни</span>
                                    </button>
                                    <div class="col-12">
                                        <div class="info-container_heading">Дані страхувальника</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Ім’я</div>
                                        <div class="form-group">
                                            <input v-model="order.info.name" type="text" placeholder="Ім’я" :class="['form-control', {invalid: (!$v.order.info.name.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.name.required && submitCheck">Вкажіть ім’я</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Прізвище</div>
                                        <div class="form-group">
                                            <input v-model="order.info.surname" type="text" placeholder="Прізвище" :class="['form-control', {invalid: (!$v.order.info.surname.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.surname.required && submitCheck">Вкажіть прізвище</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Дата народження</div>
                                        <div class="form-group">
                                            <input v-model.trim="order.info.dateBirth" v-mask="'##.##.####'" type="text" placeholder="Дата народження" :class="['form-control', 'date-input', {invalid: (!$v.order.info.dateBirth.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.dateBirth.required && submitCheck">Вкажіть дату народження</small>
                                            <small class="error" v-else-if="!$v.order.info.dateBirth.minLength">Введіть дату в форматі 12.12.2012</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">ІНН</div>
                                        <div class="form-group">
                                            <input v-model="order.info.inn" v-mask="'##########'" type="text" placeholder="ІПН" :class="['form-control', {invalid: (!$v.order.info.inn.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.inn.required && submitCheck">Вкажіть ІПН</small>
                                            <small class="error" v-if="!$v.order.info.inn.numeric">ІПН повинен містити тільки цифри</small>
                                            <small class="error" v-else-if="!$v.order.info.inn.minLength">ІПН повинен містити мінімум 10 символів</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Номер телефону</div>
                                        <div class="form-group">
                                            <input v-model.trim="order.info.phone" v-mask="'+38 (###) ###-##-##'" type="text" placeholder="Телефон" :class="['form-control', {invalid: (!$v.order.info.phone.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.phone.required && submitCheck">Вкажіть телефон</small>
                                            <small class="error" v-else-if="!$v.order.info.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="info-label">Email</div>
                                        <div class="form-group">
                                            <input v-model.trim="order.info.mail" type="text" placeholder="Ел. пошта" :class="['form-control', {invalid: (!$v.order.info.mail.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.mail.required && submitCheck">Вкажіть пошту</small>
                                            <small class="error" v-else-if="!$v.order.info.mail.email">Введіть коректно email</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="order.info.documentType === 2">
                                        <div class="info-label">Cepiя та номер закордонного паспорта</div>
                                        <div class="form-group">
                                            <input v-model="order.info.internationalPassport" type="text" placeholder="Серія та номер закордонного паспорту" :class="['form-control', {invalid: (!$v.order.info.internationalPassport.required && submitCheck)}]">
                                            <small class="error" v-if="!$v.order.info.internationalPassport.required && submitCheck">Вкажіть серію та номер закордонного паспорту</small>
                                        </div>
                                    </div>
                                    <div class="passport-row row" v-else>
                                        <div class="col-lg-6">
                                            <div class="info-label">Серія та номер паспорта</div>
                                            <div class="form-group">
                                                <input v-model="order.info.passportNumber" type="text" placeholder="Серія та номер паспорта" :class="['form-control', {invalid: (!$v.order.info.passportNumber.required && submitCheck)}]">
                                                <small class="error" v-if="!$v.order.info.passportNumber.required && submitCheck">Вкажіть серію та номер паспорта</small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="info-label">Ким виданий</div>
                                            <div class="form-group">
                                                <input v-model="order.info.passportCompany" type="text" placeholder="Ким виданий" :class="['form-control', {invalid: (!$v.order.info.passportCompany.required && submitCheck)}]">
                                                <small class="error" v-if="!$v.order.info.passportCompany.required && submitCheck">Вкажіть ким виданий</small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="info-label">Дата видачі</div>
                                            <div class="form-group">
                                                <input v-model="order.info.passportDate" v-mask="'##.##.####'" type="text" placeholder="Дата видачі" :class="['form-control', {invalid: (!$v.order.info.passportDate.required && submitCheck)}]">
                                                <small class="error" v-if="!$v.order.info.passportDate.required && submitCheck">Вкажіть дату видачі</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="info-delimetr"></div>
                                </div>
                                <div class="col-lg-6">
                                    <button class="proposition-btn" type="button" @click="showDraft">Переглянути персональную пропозиццію</button>
                                </div>
                                <div class="col-lg-12">
                                    <div class="all-price">
                                        <div class="price-label">Загальна вартість</div>
                                        <div class="price-value" v-if="order.fullPrice">{{order.fullPrice}}</div>
                                        <div class="price-value" v-else>{{order.price}} <span>грн</span></div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="info-delimetr"></div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check personal-check">
                                        <input v-model="personalCheck" type="checkbox" id="personal" class="form-check-input">
                                        <label for="personal" :class="['form-check-label', { error : !personalCheck && submitCheck}]">Згоден з обробкою персональних даних. Дані будуть передані в страхову компанію для формування поліса</label>
                                    </div>
                                </div>
<!--                                <div class="col-lg-12">-->
<!--                                    <div class="info-otp">-->
<!--                                        <button v-if="!showOtp" class="info-otp__submit" @click="showOtp = true">Відправити ОТП-код</button>-->
<!--                                        <form v-else action="#" class="info-otp__form">-->
<!--                                            <div class="form-group">-->
<!--                                                <input type="text" placeholder="СМС код" class="form-control">-->
<!--                                            </div>-->
<!--                                            <button type="button" class="info-otp__submit">Підтвердити</button>-->
<!--                                        </form>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="row col-lg-12 order-nav">
                                    <div class="col-lg-6 text-center">
                                        <button type="submit" class="btn btn-blue">
                                            Оформити
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalDocument></modalDocument>
        <modalSuccess @showload="downloadContract()"></modalSuccess>
    </main>
</template>


<script>
import Multiselect from 'vue-multiselect';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import AutocivilPromo from '@/views/flight/components/AutocivilPromo.vue';
import modalDocument from '@/components/app/modals/modal-flight-document';
import modalSuccess from '@/components/app/modals/modal-flight-success';
import citySearch from '@/components/app/forms/CitySearch.vue';
import {email, maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import {downloadOrder} from "@/api/order";
import { contractBus } from '@/api/contractBus';
import { changeOrderBus } from '@/api/changeOrderBus';

export default {
    components: {
        Multiselect,
        VueTypeaheadBootstrap,
        AutocivilPromo,
        modalDocument,
        citySearch,
        modalSuccess
    },
    data: function () {
        return {
            showOtp: false,
            createTicketInfo: false,
            createUserInfo: false,
            submitCheck: false,
            personalCheck: false,
            cityFrom: null,
            order:{
                startDate: null,
                endDate: null,
                from: null,
                to: null,
                ticketNumber: null,
                ticketPrice: null, 
                info:{
                    documentType: null,
                    inn: null,
                    name: null,
                    surname: null,
                    dateBirth: null,
                    phone: null,
                    mail: null,
                    city: null,
                    street: null,
                    house: null,
                    apartment: null,
                    internationalPassport: null,
                    passportNumber: null,
                    passportCompany: null,
                    passportDate: null
                }
            },
        }
    },

    validations: {
        order: {
            startDate:{ required, minLength: minLength(16)},
            endDate:{ required, minLength: minLength(16)},
            ticketNumber:{required},
            from:{required},
            to:{required},
            info: {
                inn:{required, minLength: minLength(10), numeric,},
                name:{required},
                surname:{required},
                dateBirth:{required, minLength: minLength(10)},
                phone:{required,minLength: minLength(19)},
                mail:{required,email},
                internationalPassport:{required: requiredIf(function (nestedModel) {
                    return this.order.info.documentType === 2
                })},
                passportNumber:{required: requiredIf(function (nestedModel) {
                    return this.order.info.documentType === 1
                })},
                passportCompany:{required: requiredIf(function (nestedModel) {
                    return this.order.info.documentType === 1
                })},
                passportDate:{required: requiredIf(function (nestedModel) {
                    return this.order.info.documentType === 1
                })}
            },
        }
        //year: {required, minLength: minLength(4), maxLength: maxLength(4), numeric}
    },

    created() {
        this.fetchOrder(this.$store.getters.BUS_FULL_ORDER);
    },

    computed: {
        cityStoreFrom() {
            return this.order.from
        },
        cityStoreTo() {
            return this.order.to
        },
    },

    methods: {
        submitHandler() {
            this.submitCheck = true;
            if (this.$v.$invalid) {
                this.$v.$touch()
                this.createTicketInfo = true
                this.createUserInfo = true
                window.scrollTo({ top: 300, behavior: 'smooth' });
                return
            }

            if(!this.personalCheck){
                return
            }

            this.createTicketInfo = false
            this.createUserInfo = false

            contractBus(this.order.orderId).then((response) => {
                this.$store.commit('SET_BUS_CONTRACT_ID', response.data.contractId);
                $('#modalFlightSuccess').modal('show');
            }).catch((error) => {
                this.$alert('Щось пішло не так. Перевірте заповнені поля.')
            });
        },
        saveChange(){
            this.submitCheck = true;
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            changeOrderBus(this.order).then((response) => {
                this.$store.commit('SET_BUS_CHANGE_ORDER', this.order);
                this.$router.go();
            }).catch((error) => {
                this.$alert('Щось пішло не так. Перевірте заповнені поля.')
            });
        },
        fetchOrder(data){
            if(data){
                this.order = data;
            }
        },
        downloadContract() {
            $('#modalFlightSuccess').modal('hide');
            downloadOrder(this.$store.getters.USER.id, this.order.orderId);
        },
        showDraft() {
            downloadOrder(this.$store.getters.USER.id, this.order.orderId);
        },
        citySelect(data) {
            this.order.from = data.city;
        },
        citySelectTo(data) {
            this.order.to = data.city;
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
