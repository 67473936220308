export function changeOrderBus(data) {
    return axios({
        url: `/api/test/insurance/bus/order/create`,
        method: 'post',
        data: {
            orderId: data.orderId,
            companyId: data.id,
            price: data.price,
            dmcPrice: (data.fullPrice) ?  data.dmc : null,
            franchise: 0,
            fromCityId: data.from.id,
            toCityId: data.to.id,
            startDate: data.startDate,
            endDate: data.endDate,
            ticketNumber: data.ticketNumber,
            ticketPrice: data.ticketPrice,
            info: {
                inn: data.info.inn,
                name: data.info.name,
                surname: data.info.surname,
                dateBirth: data.info.dateBirth,
                phone: data.info.phone,
                mail: data.info.mail,
                cityId: data.info.city.id,
                street: data.info.street,
                house: data.info.house,
                apartment: data.info.apartment,
                documentType: data.info.documentType,
                passportNumber: data.info.passportNumber,
                passportCompany: data.info.passportCompany,
                passportDate: data.info.passportDate,
                internationalPassport: data.info.internationalPassport,
            }
        },
    });
}
